// src/auth/auth.js
import { createAuth0 } from '@auth0/auth0-vue';

export const auth0 = createAuth0({
    domain: 'dev-d0nm1efwte87jpfk.us.auth0.com', // Your Auth0 domain
    clientId: 'yoCvBBBvnJ3s9qNJVW73ZBQmHOec5dyP', // Your actual Client ID
    authorizationParams: {
      redirect_uri: window.location.origin + '/callback', // Redirect to the app's current URL
      audience: 'https://myapp.com/api', // The API audience
    }
    // cacheLocation: 'localstorage',
  });
